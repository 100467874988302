* {
  margin: 0;
  padding: 0;
}

.--Intro-Heading {
  position: absolute;
  font-family: helvetica;
  font-weight: 800;
  font-size: 100px;
  backdrop-filter: blur(100px) opacity(45%);
  border-radius: 3%;
  text-shadow: black 3px 3px 20px;
}

.--Intro-Cv {
  position: absolute;
  font-family: "helvetica";
  font-weight: 800;
  font-size: 50px;
  top: 1000px;
  color: white;
  backdrop-filter: blur(100px) opacity(45%); 
}
/* Parallaximg media query's */

@media all and (max-width: 1861px) {
  /* highest pixel count! */
  .--Intro-Heading {
    font-size: 90px;
  }

  .--Intro-Cv {
    font-size: 45px;
  }
}

@media all and (max-width: 1675px) {
  .--Intro-Heading {
    font-size: 80px;
  }

  .--Intro-Cv {
    font-size: 40px;
  }
}

@media all and (max-width: 1440px) {
  .--Intro-Heading {
    font-size: 80px;
  }

  .--Intro-Cv {
    font-size: 40px;
    top: 800px;
  }
}

@media all and (max-width: 1489px) {
  .--Intro-Heading {
    font-size: 70px;
  }

  .--Intro-Cv {
    font-size: 35px;
  }
}

@media all and (max-width: 1303px) {
  .--Intro-Heading {
    font-size: 60px;
  }

  .--Intro-Cv {
    font-size: 30px;
    top: 800px;
  }
}

@media all and (max-width: 1150px) {
  .--Intro-Heading {
    font-size: 50px;
  }

  .--Intro-Cv {
    font-size: 25px;
    top: 700px;
  }
}

@media all and (max-width: 950px) {
  .--Intro-Heading {
    font-size: 45px;
  }

  .--Intro-Cv{
    font-size: 20px;
    top: 680px;
  }
}

@media all and (max-width: 850px) {
  .--Intro-Heading {
    font-size: 40px;
  }

  .--Intro-Cv {
    font-size: 20px;
    top: 500px;
  }
}

@media all and (max-width: 744px) {
  .--Intro-Heading {
    font-size: 37px;
  }

  .--Intro-Cv {
    font-size: 20px;
    top: 500px;
  }
}

@media all and (max-width: 688px) {
  .--Intro-Heading {
    font-size: 34px;
  }

  .--Intro-Cv {
    font-size: 20px;
    top: 490px;
  }
}

@media all and (max-width: 632px) {
  .--Intro-Heading {
    font-size: 31px;
  }

  .--Intro-Cv {
    font-size: 20px;
    top: 490px;
  }
}

@media all and (max-width: 577px) {
  .--Intro-Heading {
    font-size: 28px;
  }

  .--Intro-Cv {
    font-size: 15px;
    top: 490px;
  }
}

@media all and (max-width: 571px) {
  .--Intro-Cv {
    top: 405px;
  }
}

@media all and (max-width: 521px) {
  .--Intro-Heading {
    font-size: 25px;
  }

  .--Intro-Cv {
    font-size: 15px;
    top: 405px;
  }
}

@media all and (max-width: 465px) {
  .--Intro-Heading {
    font-size: 22px;
  }

  .--Intro-Cv {
    font-size: 15px;
    top: 405px;
  }
}

@media all and (max-width: 409px) {
  .--Intro-Heading {
    font-size: 18px;
  }

  .--Intro-Cv {
    font-size: 15px;
    top: 395px;
  }
}

@media all and (max-width: 335px) {
  /* lowest pixel count! */
  .--Intro-Heading {
    font-size: 17px;
  }

  .--Intro-Cv {
    font-size: 13px;
    top: 380px;
  }
}
